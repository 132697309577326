<template>
  <div class="favorite"
       v-on:click.prevent.stop="addToFavorites">
    <div class="favorite__wrapper">
      <Row v-if="ready">
        <Icon class="favorite__icon"
              :xlink="favorited ? 'bookmark-filled' : 'bookmark'"
              viewport="0 0 30 30"/>
        <slot/>
      </Row>
      <Loader v-else
              :params="{style}"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Favorite',
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      favorited: false,
      ready: true,
      style: {
        width: '30px',
        height: '30px',
      },
    };
  },
  created() {
    this.favorited = this?.params?.favorited || false;
  },
  methods: {
    addToFavorites(e) {
      e.preventDefault();
      if (this.$user.logged) {
        this.ready = false;
        this.$http.post(`/api/${this?.params?.entity}/${this?.params?.id}/favorites`)
          .then(() => {
            this.$store.dispatch(`GET_FAVORITES_${this?.params?.entity.toUpperCase()}_FROM_SERVER`);
            this.ready = true;
            this.favorited = !this.favorited;
          })
          .catch(() => {
            this.ready = true;
            this.favorited = false;
          });
      } else {
        this.$redirectIfGuest();
      }
      e.stopPropagation();
    },
  },
};
</script>
